<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="formattedDate"
        :label="label"
        :rules="rules.concat(rule)"
        :disabled="disable"
        maxlength="10"
        hint="MM/DD/YYYY format"
        outlined
        dense
        clearable
        @click:clear="clearDate"
        @change="$emit('change')"
        :hide-details="hideDetails"
      >
        <template v-slot:prepend-inner>
          <v-icon v-on="on">mdi-calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      color="primary"
      :min="minDate"
      :max="maxDate"
      @change="dateMenu = false"
      @input="today = true"
    >
      <template v-slot:default>
        <v-btn block color="primary" @click="setToday()"> Today </v-btn>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';
import { fixTimezone } from '../../helpers/format';
export default {
  name: 'date-picker',
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: () => 'Date Range'
    },
    value: {
      type: String,
      default: () => ''
    },
    minDate: {
      type: String,
      default: () => null
    },
    disable: {
      type: Boolean,
      default: () => false
    },
    hideDetails: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    dateMenu: false,
    today: false
  }),
  computed: {
    date: {
      get: function () {
        const date = fixTimezone(this.value);
        return date ? date.format('YYYY-MM-DD') : null;
      },
      set: function (v) {
        this.$emit('input', v);
      }
    },
    formattedDate: {
      get: function () {
        if (this.date) {
          const date = fixTimezone(this.date);
          const result = date ? date.format('MM/DD/YYYY') : null;
          return result;
        }
        return null;
      },
      set: function (value) {
        const date_regex =
          /([1-9]|0[1-9]|1[012])[- /.]([1-9]|0[1-9]|[12][0-9]|3[01])[- /.]\d\d\d\d/;
        if (date_regex.test(value)) {
          const date = moment(value, 'MM/DD/YYYY');
          this.date = date.isValid() ? date.format('YYYY-MM-DD') : null;
        } else this.date = null;
      }
    },
    maxDate() {
      return new Date().toISOString().split('T')[0];
    },
    rule() {
      return [
        (v) => {
          if (!v) {
            return !this.minDate || 'Required';
          }

          const date_regex =
            /([1-9]|0[1-9]|1[012])[- /.]([1-9]|0[1-9]|[12][0-9]|3[01])[- /.]\d\d\d\d/;
          if (!date_regex.test(v)) {
            return 'MM/DD/YYYY format is required';
          }

          const date = moment(v, 'MM/DD/YYYY');
          const valid = date.isValid() && date.isBefore(moment());
          return valid || 'A valid date is required';
        }
      ];
    }
  },
  methods: {
    setToday() {
      this.date = moment().format('YYYY-MM-DD');
      this.dateMenu = false;
      this.today = false;
    },
    clearDate() {
      this.date = null;
      this.$emit('clear');
    }
  }
};
</script>
